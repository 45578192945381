<template>
  <v-card elevation="3" style="width: 100%; min-height: 500px">
    <v-row v-if="!isLoading.getData" no-gutters class="mx-2">
      <!-- Button -->
      <v-col cols="12">
        <v-row justify="end" no-gutters>
          <v-btn
            icon
            class="btn-primary"
            color="primary"
            @click="setShowEditDialog(true)"
          >
            <v-icon small>fas fa-pencil</v-icon>
          </v-btn>
        </v-row>
      </v-col>

      <!-- Info -->
      <v-col cols="12">
        <v-row no-gutters>
          <!-- Color -->
          <v-col cols="12">
            <span class="font-weight-bold">
              {{ $t("accounts.tabs.settings.labels.color") }} :
            </span>
            <span v-if="item.color">{{ item.color }}</span>
            <span v-else>{{ $t("common.misc.NA") }}</span>
          </v-col>

          <!-- Website -->
          <v-col cols="12">
            <span class="font-weight-bold">
              {{ $t("accounts.tabs.settings.labels.website") }} :
            </span>
            <span v-if="item.website">{{ item.website }}</span>
            <span v-else>{{ $t("common.misc.NA") }}</span>
          </v-col>

          <!-- MyProductImpact Redirection URL -->
          <v-col cols="12">
            <span class="font-weight-bold">
              {{
                $t(
                  "accounts.tabs.settings.labels.myproductimpactRedirectionUrl"
                )
              }}
              :
            </span>
            <span v-if="item.myproductimpact_redirection_url">
              {{ item.myproductimpact_redirection_url }}
            </span>
            <span v-else>{{ $t("common.misc.NA") }}</span>
          </v-col>

          <!-- MyProductImpact Path -->
          <v-col cols="12">
            <span class="font-weight-bold">
              {{ $t("accounts.tabs.settings.labels.myproductimpactPath") }} :
            </span>
            <span v-if="item.myproductimpact_path">
              {{ item.myproductimpact_path }}
            </span>
            <span v-else>{{ $t("common.misc.NA") }}</span>
          </v-col>

          <!-- Picture -->
          <v-col cols="12">
            <v-row no-gutters justify="start" align="center">
              <h4>{{ $t("accounts.tabs.settings.picture.title") }}</h4>

              <div>
                <!-- Edit picture btn -->
                <BtnIconEdit
                  v-if="account.picture && account.picture.url"
                  @click="toggleEditPictureDialog(true)"
                />
                <BtnIconAdd v-else @click="toggleEditPictureDialog(true)" />

                <!-- Delete picture btn -->
                <BtnIconDelete
                  v-if="account.picture && account.picture.url"
                  @click="deleteAccountPicture(account.picture.id)"
                />
              </div>
            </v-row>
            <v-img
              v-if="account.picture"
              :src="account.picture.URL"
              width="400"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <Skeleton v-else type="table" :occurrence="1" />

    <!-- AccountSettingsMainDialog -->
    <AccountSettingsMainDialog
      v-if="showDialog.edit"
      :value="showDialog.edit"
      :item="item"
      :account="account"
      @close="(needRefresh) => setShowEditDialog(false, needRefresh)"
    />

    <!-- Picture upload -->
    <ChangePictureDialog
      v-if="showDialog.editPicture"
      :value="showDialog.editPicture"
      :UPDATE_API="`/accounts/${account.id}/picture`"
      @close="(needRefresh) => toggleEditPictureDialog(false, needRefresh)"
    />
  </v-card>
</template>

<script>
export default {
  name: "AccountSettingsTab",

  components: {
    AccountSettingsMainDialog: () =>
      import("@/components/Accounts/Settings/AccountSettingsMainDialog"),
    Skeleton: () => import("@/components/Common/Cards/Skeleton"),
    BtnIconDelete: () => import("@/components/Common/Buttons/BtnIconDelete"),
    BtnIconEdit: () => import("@/components/Common/Buttons/BtnIconEdit"),
    BtnIconAdd: () => import("@/components/Common/Buttons/BtnIconAdd"),
    ChangePictureDialog: () =>
      import("@/components/Common/Picture/ChangePictureDialog"),
  },

  props: {
    account: { type: Object, default: () => null },
  },

  data() {
    return {
      item: null,
      showDialog: {
        edit: false,
        editPicture: false,
      },

      isLoading: {
        getData: true,
        btnPictureDelete: false,
      },
    };
  },

  mounted() {
    this.getData();
  },

  methods: {
    getData() {
      this.isLoading.getData = true;

      this.$http
        .get(`/accounts/${this.account.id}/settings`, {
          headers: { Authorization: "Bearer " + this.$session.get("jwt") },
        })
        .then((res) => {
          this.item = res.data;
        })
        .catch((err) => {
          this.$store.commit("alert/showErrorAxios", err);
        })
        .finally(() => {
          this.isLoading.getData = false;
        });
    },

    setShowEditDialog(val, needRefresh = false) {
      if (needRefresh) this.getData();
      this.showDialog.edit = val;
    },

    toggleEditPictureDialog(val, needRefresh = false) {
      if (needRefresh) this.$emit("refresh");
      this.showDialog.editPicture = val;
    },

    deleteAccountPicture(pictureId) {
      this.$http
        .delete(`/accounts/${this.account.id}/pictures/${pictureId}`, {
          headers: { Authorization: "Bearer " + this.$session.get("jwt") },
        })
        .then((res) => {
          this.$emit("refresh");
        })
        .catch((err) => {
          this.$store.commit("alert/showErrorAxios", err);
        })
        .finally(() => {
          this.isLoading.btnPictureDelete = false;
        });
    },
  },
};
</script>

<style scoped></style>
